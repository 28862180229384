import { Entity } from '../common/base/entity/entity'
import { collectionSchemas, entitySchema, methodSchemas } from './workspace.schema'

class WorkspacePageEntity extends Entity {
    // override the following if needed.
    // derived actionObj
    // derived Service obj
    // getActionFns if you want to suppress the baseActionobj methods
}


const fieldsMeta = {
    name: {
        label: 'Name',
        name: 'name',
        type: 'text'
    },
    label: {
        label: 'Label',
        name: 'label',
        type: 'text'
    },
    workspaceType: {
        label: "Workspace Type",
        name: 'workspaceType',
        options: [{label: 'qna', value: "qna"}, {label: 'livechat', value: "livechat"} ],
        type: 'select',
        required: true,
        default: 'qna',
    },
    role: {
        label: "Role",
        name: 'role',
        options: [{label: 'Read', value: "Read"}, {label: 'Edit', value: "Edit"}, {label: 'Approve', value: "Approve"} ],
        type: 'select',
        required: true,
        default: 'Edit',
    },
    properties: {
        label: 'Properties',
        name: 'properties',
        type: 'customJsonClone',
        required: true,
    },
    status: {
        label: "Status",
        name: 'status',
        options: [{label: 'Published', value: "Published"}, {label: 'Draft', value: "Draft"}],
        type: 'select',
        required: true,
        default: 'Published',
    },
    updatedBy: {
        label: "Updated By",
        name: 'updatedBy',
        // options: [{label: 'Published', value: "Published"}, {label: 'Draft', value: "Draft"}],
        type: 'text',
        required: true,
    },
    updatedAt: {
        label: "Updated At",
        name: 'updatedAt',
        // options: [{label: 'Published', value: "Published"}, {label: 'Draft', value: "Draft"}],
        type: 'datetime-local',
        required: true,
    }
}


const actionsMeta = {
    create:{
        label: 'Create',
        action: 'addNewItem',
        name : 'create',
        type: 'button'
    },
    upsert: {
        type: 'button',
        label: 'Update',
        name: 'Update',
        action: 'upsertItem',
    },
    edit: {
        type: 'button',
        label: 'Update',
        action: 'editItem',
        name: 'edit'
    },
    save: {
        label: 'Save',
        name: 'save',
        action: 'addNewItem',
        type: 'button'
    },
    cancel: {
        label: 'Cancel',
        name: 'cancel',
        action: 'cancelEdit'
    },
    update: {
        label: 'Update',
        name: 'update',
        action: 'editItem',
        classes: 'fa fa-pencil fa-lg ',
        type: 'span'
    },
    list: {
        label: 'List All',
        name: 'list',
        action: 'load'
    },
    search: {
        label: 'Search',
        name: 'search',
        action: 'search'
    },
    delete:{
        label:"Delete",
        name:"delete",
        action:'deleteItem',
        backgroundColor:'danger',
        confirmAction: true,
        confirmActionMessage: 'Are you sure, You want to delete?'
    }
}


const sectionsMeta = {
    // general: {
    //     items: [{name:'botId', type: 'field'}, {name:'name', type: 'field'}, {name:'label', type: 'field'}, {name:'role', type: 'field'}, {name:'rule', type: 'field'},{name:'kbId', type: 'field'}, {name:'status', type: 'field'}, {name:'updatedBy', type: 'field'}, {name:'updatedAt', type: 'field'}],
    // },
    basic: {
        title: 'Workspace Detail',
        items: [{name:'name', type: 'field'}, {name:'label', type: 'field'}, {name:'workspaceType', type: 'field'}, {name:'role', type: 'field'}, {name:'properties', type: 'field'}, {name:'status', type: 'field'}],
        classes:"user-attribute-form"
    },
    headerComponent: {
        component: 'DetailHeader',
    },
    WorkspaceCreateForm: {
        cols: [7,5],
        items:[{name: 'basic', type: 'section', col: 1}, 
        {name: 'save', type: 'action', col: 1}],
        classes: 'composite-inner-form'
    },
    WorkspaceEditForm: {
        cols: [7,5],
        items:[{name: 'basic', type: 'section', col: 1}, 
        {name: 'update', type: 'action', col: 1}],
        classes: 'composite-inner-form'
    }
    
}



const screens = {
    view: {
        items: [{name:'headerComponent', type: 'section'}, {name: 'WorkspaceEditForm', type: 'section'}],
        classes: 'composite-form',
        title: 'Workspace'
    },
    // edit:{
    //     items: [{name:'headerComponent', type: 'section'}, {name: 'userAttributeForm', type: 'section'}]
    // },
    create:{
        items: [{name:'headerComponent', type: 'section'}, {name: 'WorkspaceCreateForm', type: 'section'}]
    },
    // edit: {
    //     items: [{name:'headerComponent', type: 'section'}, {name: 'WorkspaceEditForm', type: 'section'}]
    // },
    list: {
        title: 'WorkSpace',
        items: [
            {name:'name', type: 'link', row: 1, col: 3}, 
            {name:'label', type: 'field', row: 2, col: 1},
            {name:'workspaceType', type: 'field', row: 2, col: 1},
            {name:'role', type: 'field', row: 2, col: 2},
            {name:'properties', type: 'field', row:2, col: 3}, 
            {name:'status', type: 'field', row: 3, col: 1},
            {name:'updatedBy', type: 'field', row: 3, col: 2},
            {name:'updatedAt', type: 'date', row: 3, col: 3}
        ],
        layoutType: {
            type:['Card'],backgroundColor:'#8E8CD8', cardInRow:3, color:'#fff' 
        },
        multiselect: false,
    }
}


const Workspace = new WorkspacePageEntity({ fieldsMeta, actionsMeta, sectionsMeta, screens, name: 'Workspace', title: 'Manage Workspace', methodSchemas, collectionSchemas,  entitySchema, entityUrlPaths:{default: "workspace", update: "workspace/update" }, fetchDetailByApi: false, idKey: "id" })


const WorkspaceSearchContainer = Workspace.getContainer('ListSearch')
const WorkspaceCreateContainer = Workspace.getContainer('Create')
const WorkspaceDetailContainer = Workspace.getContainer('Detail')
export { WorkspaceSearchContainer, WorkspaceCreateContainer, WorkspaceDetailContainer }

