import React from 'react'
import { Router as BrowserRouter, Route, Switch } from 'react-router-dom'
import { appRootPath } from '../config'
import { history } from './history'
// Custom Imports
import Loadable from 'react-loadable'
import {
	LandingPageSettingCreateContainer,
	LandingPageSettingDetailContainer,
	LandingPageSettingListSearchContainer,
} from '../app/LandingPageSettings/LandingPage'
import { TenantMgmtPageCreateContainer, TenantMgmtPageDetailContainer, TenantMgmtPageListSearchContainer } from '../app/TenantManagement/TenantPage'
import { ClientContainer } from '../app/clients/clientContainer'
import { PrivateRoute, PrivateRouteCustom } from '../app/common'
import { SettingsDetailContainer, SettingsListSearchContainer } from '../app/common/base/entities/settings/settings'
import { TestDashboardContainer } from '../app/dashboard/adminDashboard'
import { IntentBotCreateContainer, IntentBotDetailContainer, IntentBotListSearchContainer } from '../app/intent_bot/intentBot'
import { SubscriptionContainer } from '../app/subscription/container/subscription'

import { AuditLogDetailContainer, AuditLogListSearchContainer } from '../app/common/auditLog/auditLog'
import { AuditConfigCreateContainer, AuditConfigDetailContainer, AuditConfigListSearchContainer } from '../app/common/base/entities/auditConfig/auditConfig'

import { businessHoursCreateContainer, businessHoursDetailContainer, businessHoursListSearchContainer } from '../app/businessHours/businessHours'
import { holidaysCreateContainer, holidaysDetailContainer, holidaysListSearchContainer } from '../app/holidays/holidays'
import { RoleGroupCreateContainer, RoleGroupDetailContainer, RoleGroupListSearchContainer } from '../app/role-group/roleGroup'
import { userAttributesCreateContainer, userAttributesDetailContainer, userAttributesSearchContainer } from '../app/user-attributes/user.attributes'
import {
	UserManagementCreateContainer,
	UserManagementDetailContainer,
	UserManagementImportContainer,
	UserManagementListSearchContainer,
} from '../app/user-management/Users'
import { WorkspaceCreateContainer, WorkspaceDetailContainer, WorkspaceSearchContainer } from '../app/workspace/workspace'

import { calendarCreateContainer, calendarDetailContainer, calendarListSearchContainer } from '../app/calendar/calendar'

import { CustomerCreateContainer, CustomerDetailContainer, CustomerListSearchContainer } from '../app/customer/customer'
import { GroupCreateContainer, GroupDetailContainer, GroupListSearchContainer } from '../app/group/group'
import { HiOutlineUserGroup, HiOutlineTicket, HiOutlineWrench } from "react-icons/hi2"
import { TfiComments } from "react-icons/tfi"
import { BsSliders } from "react-icons/bs"
import { MdOutlineSupportAgent, MdDashboard } from "react-icons/md"
import { Box } from '@chakra-ui/react'
import { RxDashboard } from "react-icons/rx";
import { IoTicketOutline } from "react-icons/io5";

function Loading() {
	return <div className='loader'></div>
}
// const LoadableEntityContainers = {}
// Object.keys(EntityContainers).forEach(c=>{
//     LoadableEntityContainers[c] = Loadable({
//         loader: () => EntityContainers[c],
//         loading: Loading
//     })
// })
// const ClientList = Loadable({
// 	// loader: () => ClientContainer,
// 	loader: () => ApiClientsListSearchContainer,
// 	loading: Loading,
// })
const TestDashboard = Loadable({
	loader: () => TestDashboardContainer,
	loading: Loading,
})
const SettingsList = Loadable({
	loader: () => SettingsListSearchContainer,
	// let comp = import('../app/common/base/entities/settings/settings')
	loading: Loading,
})
const SettingsDetail = Loadable({
	loader: () => SettingsDetailContainer,
	// let comp = import('../app/common/base/entities/settings/settings')
	loading: Loading,
})
const Home = Loadable({
	loader: () => import('../app/common/containers/Home'),
	loading: Loading,
})
const RegisterContainer = Loadable({
	loader: () => import('../app/register-tenant/containers/RegisterContainer'),
	loading: Loading,
})
const ErrorPage = Loadable({
	loader: () => import('../app/common/containers/ErrorPage'),
	loading: Loading,
})
const Logout = Loadable({
	loader: () => import('../app/common/containers/Logout'),
	loading: Loading,
})
const RoleManagementContainer = Loadable({
	loader: () => import('../app/role-management/containers/RoleManagementContainer'),
	loading: Loading,
})
const GlobleSettings = Loadable({
	loader: () => import('../app/sso-management/containers/SsoManagementContainer'),
	loading: Loading,
})
const LandingPageSettingsList = Loadable({
	loader: () => LandingPageSettingListSearchContainer,
	loading: Loading,
})
const LandingPageSettingsDetail = Loadable({
	loader: () => LandingPageSettingDetailContainer,
	loading: Loading,
})
const CreateLandingSettingsDetail = Loadable({
	loader: () => LandingPageSettingCreateContainer,
	loading: Loading,
})
const CreateTenantDetail = Loadable({
	loader: () => TenantMgmtPageCreateContainer,
	loading: Loading,
})
const TenantPageDetail = Loadable({
	loader: () => TenantMgmtPageDetailContainer,
	loading: Loading,
})
const TenantPageList = Loadable({
	loader: () => TenantMgmtPageListSearchContainer,
	loading: Loading,
})

const UserAttributesSearch = Loadable({
	loader: () => userAttributesSearchContainer,
	loading: Loading,
})

const UserAttributesCreate = Loadable({
	loader: () => userAttributesCreateContainer,
	loading: Loading,
})

const UserAttributesUpdate = Loadable({
	loader: () => userAttributesDetailContainer,
	loading: Loading,
})
const WorkspaceSearchComponent = Loadable({
	loader: () => WorkspaceSearchContainer,
	loading: Loading,
})
const WorkspaceCreateComponent = Loadable({
	loader: () => WorkspaceCreateContainer,
	loading: Loading,
})
const WorkspaceDetailComponent = Loadable({
	loader: () => WorkspaceDetailContainer,
	loading: Loading,
})

const BusinessHoursListComponent = Loadable({
	loader: () => businessHoursListSearchContainer,
	loading: Loading,
})
const BusinessHoursCreateComponent = Loadable({
	loader: () => businessHoursCreateContainer,
	loading: Loading,
})
const BusinessHoursDetailComponent = Loadable({
	loader: () => businessHoursDetailContainer,
	loading: Loading,
})

const HolidaysListComponent = Loadable({
	loader: () => holidaysListSearchContainer,
	loading: Loading,
})
const HolidaysCreateComponent = Loadable({
	loader: () => holidaysCreateContainer,
	loading: Loading,
})
const HolidaysDetailComponent = Loadable({
	loader: () => holidaysDetailContainer,
	loading: Loading,
})

const CalendarListComponent = Loadable({
	loader: () => calendarListSearchContainer,
	loading: Loading,
})
const CalendarCreateComponent = Loadable({
	loader: () => calendarCreateContainer,
	loading: Loading,
})
const CalendarDetailComponent = Loadable({
	loader: () => calendarDetailContainer,
	loading: Loading,
})

// const Dashboard = Loadable({
//     loader: () => import('../app/dashboard/containers/Dashboard'),
//     loading: Loading,
// });
// const Task = Loadable({
//     loader: () => import('../app/manage-task/containers/Talk'),
//     loading: Loading,
// });
const ProfileContainer = Loadable({
	loader: () => import('../app/common/containers/ProfileContainer'),
	loading: Loading,
})
const IntentBotList = Loadable({
	loader: () => IntentBotListSearchContainer,
	loading: Loading,
})
const IntentBotDetail = Loadable({
	loader: () => IntentBotDetailContainer,
	loading: Loading,
})
const IntentBotCreate = Loadable({
	loader: () => IntentBotCreateContainer,
	loading: Loading,
})

const Subscription = Loadable({
	loader: () => Promise.resolve(SubscriptionContainer),
	loading: Loading,
})

const AuditConfigList = Loadable({
	loader: () => AuditConfigListSearchContainer,
	loading: Loading,
})
const AuditConfigDetail = Loadable({
	loader: () => AuditConfigDetailContainer,
	loading: Loading,
})
const AuditConfigCreate = Loadable({
	loader: () => AuditConfigCreateContainer,
	loading: Loading,
})

const AuditLogList = Loadable({
	loader: () => AuditLogListSearchContainer,
	// let comp = import('../app/common/base/entities/settings/settings')
	loading: Loading,
})

const AuditLogDetail = Loadable({
	loader: () => AuditLogDetailContainer,
	// let comp = import('../app/common/base/entities/settings/settings')
	loading: Loading,
})

const RoleGroupList = Loadable({
	loader: () => RoleGroupListSearchContainer,
	loading: Loading,
})
const RoleGroupDetail = Loadable({
	loader: () => RoleGroupDetailContainer,
	loading: Loading,
})
const RoleGroupCreate = Loadable({
	loader: () => RoleGroupCreateContainer,
	loading: Loading,
})

let subdomain = false
let full = window.location.host
let parts = full.split('.')
let sub = parts[0].toLowerCase()
// let lPage = BotPatelcoContainer;
// if (publicPageUsers) {
//     let publicUsers = publicPageUsers.split(',');

//     if (publicUsers.indexOf(sub) !== -1) {
//         subdomain = true;

//     }
//     if (sub=='ms') {
//         lPage = BotMSContainer;
//     }

// }

const SupportTicketListing = Loadable({
	loader: () => import('../app/common/ticketing/containers/SupportTicketListingContainer'),
	loading: Loading,
})
const SupportTicket = Loadable({
	loader: () => import('../app/common/ticketing/containers/CreateSupportTicketContainer'),
	loading: Loading,
})

const DetailSupportTicket = Loadable({
	loader: () => import('../app/common/ticketing/containers/DetailSupportTicketContainer'),
	loading: Loading,
})

const UserManagementListing = Loadable({
	loader: () => UserManagementListSearchContainer,
	loading: Loading,
})
const UserManagementCreate = Loadable({
	loader: () => UserManagementCreateContainer,
	loading: Loading,
})

const UserManagementDetail = Loadable({
	loader: () => UserManagementDetailContainer,
	loading: Loading,
})
const UserManagementImport = Loadable({
	loader: () => UserManagementImportContainer,
	loading: Loading,
})

const GroupList = Loadable({
	loader: () => GroupListSearchContainer,
	loading: Loading,
})
const GroupDetail = Loadable({
	loader: () => GroupDetailContainer,
	loading: Loading,
})
const GroupCreate = Loadable({
	loader: () => GroupCreateContainer,
	loading: Loading,
})

const CustomerList = Loadable({
	loader: () => CustomerListSearchContainer,
	loading: Loading,
})
const CustomerDetail = Loadable({
	loader: () => CustomerDetailContainer,
	loading: Loading,
})
const CustomerCreate = Loadable({
	loader: () => CustomerCreateContainer,
	loading: Loading,
})

let tenant = localStorage.getItem( 'tenant')
let v2Only = false
let aibot = false
if (tenant && tenant !== "undefined" && typeof tenant === 'string'){
    const tenantData = JSON.parse(tenant || '{}')
    v2Only = tenantData?.custom?.v2Only || false
	aibot = tenantData?.custom?.aibot || false
} 

const v2OnlyRoutes =
	[{
		path: '/agent-ui-v2/inbox',
		exact: true,
		app: { path: 'agent-ui-v2/inbox' },
		isNav: false,
		name: 'tickets',
		label: 'Ticket Management',
		icon: <HiOutlineTicket />,
		allowedRole: ['ticket_user', 'ticket_agent', 'ticket_agent_supervisor', 'ticket_admin'],
		conditionalRoute: { accessKey: 'commonUiOptions.defaultLandingPage.ticket' }
	}]


const v1OnlyRoutes =
	[{
		path: '/agent/inbox',
		exact: true,
		app: { path: 'agent/inbox' },
		isNav: false,
		name: 'tickets',
		label: 'Ticket Management',
		icon: <HiOutlineTicket />,
		allowedRole: ['ticket_user', 'ticket_agent', 'ticket_agent_supervisor', 'ticket_admin'],
		conditionalRoute: { accessKey: 'commonUiOptions.defaultLandingPage.ticket' }
	}]

const betaRoutes = [{
	path: '/',
	exact: true,
	label: 'Ticket Management V2',
	name: 'ticketManagementV2',
	isNav: true,
	allowedRole: ['beta_user'],
	icon: <Box
		color="white"
		marginTop={'5px'}
		px="5px"
		fontSize="8px"
		lineHeight="normal"
		justifyContent="center"
		alignItems="center"
		py="1px"
		style={{
			borderRadius: '0px 10px',
			background:
				'linear-gradient(110deg, #6DD2F5 18.55%, #677BF2 89.65%)',
		}}
	>
		Beta
	</Box>,
	children: [
		{
			path: '/catalog-v2',
			exact: true,
			app: { path: 'catalog-v2' },
			isNav: true,
			name: 'catalog',
			label: 'Service Catalog',
			icon: <HiOutlineWrench />,
			allowedRole: ['beta_user'],
		},
		{
			path: '/agent-ui-v2/inbox',
			exact: true,
			app: { path: 'agent-ui-v2/inbox' },
			isNav: true,
			name: 'myTicketsV2',
			label: 'My Tickets V2',
			allowedRole: ['beta_user'],
			icon: <IoTicketOutline />,
		},
		{
			path: '/dashboard',
			exact: true,
			app: { path: 'dashboard' },
			isNav: true,
			name: 'ticketsDashboard',
			label: 'Tickets Dashboard',
			allowedRole: ['beta_user'],
			icon: <RxDashboard />,
		}
	],
}]

// const selectedRoutes =   v1OnlyRoutes;

export const routeList = [
	// Open Routes
	{ path: '/', exact: true, component: Home, isNav: false },
	{ path: '/login', exact: true, component: Home, isNav: false },
	{ path: '/logout', exact: true, component: Logout, isNav: false },
	{
		path: '/register',
		exact: true,
		component: RegisterContainer,
		isNav: false,
	},
	{ path: '/register/:product', component: RegisterContainer, isNav: false },
	{
		path: '/botmanagement',
		exact: true,
		app: { path: 'botmanagement' },
		isNav: true,
		name: 'virtualAgentMgmt',
		label: 'Virtual Agent Mgmt',
		icon: <HiOutlineUserGroup />,
		allowedRole: ['bot_analyst', 'bot_admin'],
	},
	// {
	// 	path: '/catalog',
	// 	exact: true,
	// 	app: { path: 'catalog' },
	// 	isNav: true,
	// 	name: 'catalog',
	// 	label: 'Service Catalog',
	// 	icon: <HiOutlineWrench/>,
	// 	allowedRole: ['asc_admin'],
	// },
	// {
	// 	path: '/approvals',
	// 	exact: true,
	// 	app: { path: 'approvals' },
	// 	isNav: false,
	// 	name: 'tickets',
	// 	label: 'Requests Management',
	// 	icon: <HiOutlineTicket/>,
	// 	allowedRole: ['approver', 'requestor'],
	// },
	// {
	// 	path: '/agent-ui/inbox',
	// 	exact: true,
	// 	app: { path: 'agent-ui/inbox' },
	// 	isNav: false,
	// 	name: 'tickets',
	// 	label: 'Ticket Management',
	// 	icon: <HiOutlineTicket/>,
	// 	allowedRole: ['ticket_user', 'ticket_agent', 'ticket_agent_supervisor', 'ticket_admin'],
	// 	conditionalRoute:{accessKey:'commonUiOptions.defaultLandingPage.ticket'}
	// },
	...(!v2Only ? v1OnlyRoutes : v2OnlyRoutes),
	{
		path: '/livechat',
		exact: true,
		app: { path: 'livechat' },
		isNav: false,
		name: 'liveChat',
		label: 'Live Chat',
		icon: <TfiComments />,
		allowedRole: ['chat_agent', 'chat_agent_admin', 'chat_agent_supervisor'],
	},
	// {
	// 	path: '/dashboard',
	// 	exact: true,
	// 	app: { path: 'dashboard' },
	// 	isNav: true,
	// 	name: 'dashboard',
	// 	label: 'Dashboard & Reports',
	// 	icon: 'fa fa-area-chart',
	// 	allowedRole: [
	// 		// 'ticket_agent',
	// 		// 'ticket_agent_supervisor',
	// 		// 'ticket_admin',
	// 		// 'user_admin',
	// 		// 'tenant_admin',
	// 		// 'approver',
	// 		// 'requestor',
	// 		// 'content_author',
	// 		// 'coach',
	// 		// 'expert',
	// 	],
	// },

	{
		path: '/agents/:offerName',
		exact: true,
		component: RegisterContainer,
		isNav: false,
		name: 'offer',
	},
	{
		path: '/learning',
		exact: true,
		app: { path: 'learning' },
		isNav: false,
		name: 'learningApp',
		label: 'Learning Mgmt',
		icon: 'fa fa-black-tie',
		allowedRole: ['trainee', 'content_author', 'coach', 'expert'],
	},
	{
		path: '/home',
		exact: true,
		label: 'Admin App',
		name: 'admin',
		isNav: true,
		component: Home,
		allowedRole: ['user_admin', 'tenant_admin'],
		icon: <BsSliders />,
		children: [
			// { path: '/user-management', exact: true, component: RoleManagementContainer, isNav: true, name: 'userManagement', label: 'User Management', icon: 'fa fa-users', allowedRole: ['user_admin'] },
			{
				path: '/user-management',
				exact: true,
				component: UserManagementListing,
				isNav: true,
				name: 'userManagement',
				label: 'User Management',
				icon: 'fa fa-users',
				allowedRole: ['user_admin'],
			},
			{
				path: '/user-management/create',
				exact: true,
				component: UserManagementCreate,
				isNav: false,
				name: 'userAttributeCreate',
				label: 'Attributes',
				icon: 'fa fa-users',
				allowedRole: ['user_admin'],
			},
			{
				path: '/user-management/update/:itemId',
				exact: true,
				component: UserManagementDetail,
				isNav: false,
				name: 'userAttributeCreate',
				label: 'Attributes',
				icon: 'fa fa-users',
				allowedRole: ['user_admin'],
			},
			{
				path: '/user-management/importData',
				exact: true,
				component: UserManagementImport,
				isNav: false,
				allowedRole: ['user_admin'],
				name: 'userImport',
				label: 'Users Import',
			},
			{
				path: '/user-attributes',
				exact: true,
				component: UserAttributesSearch,
				isNav: true,
				name: 'userAttributeManagement',
				label: 'Attributes',
				icon: 'fa fa-users',
				allowedRole: ['user_admin'],
			},
			{
				path: '/user-attributes/create',
				exact: true,
				component: UserAttributesCreate,
				isNav: false,
				name: 'userAttributeCreate',
				label: 'Attributes',
				icon: 'fa fa-users',
				allowedRole: ['user_admin'],
			},
			{
				path: '/user-attributes/update/:itemId',
				exact: true,
				component: UserAttributesUpdate,
				isNav: false,
				name: 'userAttributeCreate',
				label: 'Attributes',
				icon: 'fa fa-users',
				allowedRole: ['user_admin'],
			},
			// { path: '/subscription', exact: true, component: Subscription, isNav: true, name: 'subscriptions', label: 'Subscriptions', icon: 'fa fa-users', allowedRole: ['tenant_admin'] },

			{
				path: '/workspace',
				exact: true,
				component: WorkspaceSearchComponent,
				isNav: true,
				name: 'WorkspaceManagement',
				label: 'Workspace',
				icon: 'fa fa-users',
				allowedRole: ['ASC Admin', 'tenant_admin', 'user_admin'],
			},
			{
				path: '/workspace/create',
				exact: true,
				component: WorkspaceCreateComponent,
				isNav: false,
				name: 'WorkspaceCreate',
				label: 'Workspace',
				icon: 'fa fa-users',
				allowedRole: ['ASC Admin', 'tenant_admin', 'user_admin'],
			},
			{
				path: '/workspace/update/:itemId',
				exact: true,
				component: WorkspaceDetailComponent,
				isNav: false,
				name: 'WorkspaceUpdate',
				label: 'Workspace',
				icon: 'fa fa-users',
				allowedRole: ['ASC Admin', 'tenant_admin', 'user_admin'],
			},

			{
				path: '/group',
				exact: true,
				component: GroupList,
				isNav: true,
				name: 'Group',
				label: 'Group',
				icon: 'fa fa-users',
				allowedRole: ['user_admin'],
			},
			{
				path: '/group/create',
				exact: true,
				component: GroupCreate,
				isNav: false,
				name: 'GroupCreate',
				label: 'Workspace',
				icon: 'fa fa-users',
				allowedRole: ['user_admin'],
			},
			{
				path: '/group/update/:itemId',
				exact: true,
				component: GroupDetail,
				isNav: false,
				name: 'GroupUpdate',
				label: 'Group',
				icon: 'fa fa-users',
				allowedRole: ['user_admin'],
			},
			{
				path: '/customer',
				exact: true,
				component: CustomerList,
				isNav: true,
				name: 'Customer',
				label: 'Customer',
				icon: 'fa fa-users',
				allowedRole: ['user_admin'],
			},
			{
				path: '/customer/create',
				exact: true,
				component: CustomerCreate,
				isNav: false,
				name: 'CustomerCreate',
				label: 'Customer',
				icon: 'fa fa-users',
				allowedRole: ['user_admin'],
			},
			{
				path: '/customer/update/:itemId',
				exact: true,
				component: CustomerDetail,
				isNav: false,
				name: 'CustomerUpdate',
				label: 'Customer',
				icon: 'fa fa-users',
				allowedRole: ['user_admin'],
			},
			{
				path: '/clients',
				exact: true,
				component: ClientContainer,
				isNav: true,
				name: 'Clients',
				label: 'Api Clients',
				icon: 'fa fa-users',
				allowedRole: ['tenant_admin'],
			},

			{
				path: '/global-settings',
				exact: true,
				isNav: true,
				component: GlobleSettings,
				name: 'globalSettings',
				label: 'Global Settings',
				allowedRole: ['ASC Admin', 'ticket_admin'],
				className: 'global-setting',
				icon: 'fa fa-cogs',
				children: [
					{
						path: '/sso',
						exact: true,
						component: GlobleSettings,
						isNav: true,
						name: 'SSO',
						label: 'SSO',
						icon: 'fa fa-cogs',
						allowedRole: ['tenant_admin'],
					},
					{
						path: '/white-label',
						exact: true,
						component: LandingPageSettingsList,
						isNav: true,
						icon: 'fa fa-cog',
						name: 'whiteLabel',
						label: 'White Label Settings',
						allowedRole: ['ASC Admin'],
					},
					{
						path: '/white-label/update/:itemId',
						exact: true,
						component: LandingPageSettingsDetail,
						isNav: false,
						name: 'whiteLabel',
						label: 'White Label Details',
						allowedRole: ['ASC Admin'],
					},
					{
						path: '/IntentBot',
						exact: true,
						component: IntentBotList,
						isNav: true,
						icon: 'fa fa-code-fork',
						allowedRole: ['tenant_admin'],
						name: 'IntentBot',
						label: 'Intent Bot',
					},
					{
						path: '/IntentBot/update/:itemId',
						exact: true,
						component: /* subdomain ? lPage :*/ IntentBotDetail,
						isNav: false,
						allowedRole: ['tenant_admin'],
						name: 'IntentBot',
						label: 'Intent Bot Detail',
					},
					{
						path: '/IntentBot/create',
						exact: true,
						component: /* subdomain ? lPage :*/ IntentBotCreate,
						isNav: false,
						allowedRole: ['tenant_admin'],
						name: 'IntentBot',
						label: 'Create Intent Bot',
					},
					{
						path: '/AuditConfig/create',
						exact: true,
						component: /* subdomain ? lPage :*/ AuditConfigCreate,
						isNav: false,
						allowedRole: ['tenant_admin'],
						name: 'AuditConfigCreate',
					},
					{
						path: '/AuditConfig',
						icon: 'fa fa-tags',
						exact: true,
						component: /* subdomain ? lPage :*/ AuditConfigList,
						isNav: true,
						allowedRole: ['tenant_admin'],
						name: 'auditConfig',
						label: 'Audit Config',
					},
					{
						path: '/AuditConfig/update/:itemId',
						exact: true,
						component: /* subdomain ? lPage :*/ AuditConfigDetail,
						isNav: false,
						allowedRole: ['tenant_admin'],
						name: 'auditConfigDetail',
						label: 'Audit Config Detail',
					},
					{
						path: '/:baseResource/auditLogList',
						icon: 'fa fa-tags',
						exact: true,
						component: /* subdomain ? lPage :*/ AuditLogList,
						isNav: false,
						allowedRole: ['tenant_admin'],
						name: 'auditLog',
						label: 'AduitLog',
					},
					{
						path: '/:baseResource/auditLogList/update/:itemId',
						icon: 'fa fa-tags',
						exact: true,
						component: /* subdomain ? lPage :*/ AuditLogDetail,
						isNav: false,
						allowedRole: ['tenant_admin'],
						name: 'auditLogDetail',
						label: 'Aduit Log Detail',
					},
					{
						path: '/RoleGroup',
						exact: true,
						component: RoleGroupList,
						isNav: true,
						icon: 'fa fa-code-fork',
						allowedRole: ['tenant_admin'],
						name: 'IntentBot',
						label: 'Manage Role Groups',
					},
					{
						path: '/RoleGroup/update/:itemId',
						exact: true,
						component: /* subdomain ? lPage :*/ RoleGroupDetail,
						isNav: false,
						allowedRole: ['tenant_admin'],
						name: 'IntentBot',
						label: 'Intent Bot Detail',
					},
					{
						path: '/RoleGroup/create',
						exact: true,
						component: /* subdomain ? lPage :*/ RoleGroupCreate,
						isNav: false,
						allowedRole: ['tenant_admin'],
						name: 'IntentBot',
						label: 'Create Intent Bot',
					},

					{
						path: '/businessHours',
						exact: true,
						component: BusinessHoursListComponent,
						isNav: true,
						name: 'BusinessHours',
						label: 'Business Hours',
						icon: 'fa fa-clock-o',
						allowedRole: ['ticket_admin'],
					},
					{
						path: '/businessHours/create',
						exact: true,
						component: BusinessHoursCreateComponent,
						isNav: false,
						name: 'BusinessHoursCreate',
						label: 'Business Hours',
						icon: 'fa fa-users',
						allowedRole: ['ticket_admin'],
					},
					{
						path: '/businessHours/update/:itemId',
						exact: true,
						component: BusinessHoursDetailComponent,
						isNav: false,
						name: 'BusinessHoursUpdate',
						label: 'Business Hours',
						icon: 'fa fa-users',
						allowedRole: ['ticket_admin'],
					},

					{
						path: '/holidays',
						exact: true,
						component: HolidaysListComponent,
						isNav: true,
						name: 'Holidays',
						label: 'Holidays',
						icon: 'fa fa-calendar',
						allowedRole: ['ticket_admin', 'tenant_admin'],
					},
					{
						path: '/holidays/create',
						exact: true,
						component: HolidaysCreateComponent,
						isNav: false,
						name: 'HolidaysCreate',
						label: 'Holidays',
						icon: 'fa fa-users',
						allowedRole: ['ticket_admin', 'tenant_admin'],
					},
					{
						path: '/holidays/update/:itemId',
						exact: true,
						component: HolidaysDetailComponent,
						isNav: false,
						name: 'HolidaysUpdate',
						label: 'Holidays',
						icon: 'fa fa-users',
						allowedRole: ['ticket_admin', 'tenant_admin'],
					},

					{
						path: '/calendar',
						exact: true,
						component: CalendarListComponent,
						isNav: true,
						name: 'calendar',
						label: 'Calendar',
						icon: 'fa fa-calendar',
						allowedRole: ['ticket_admin', 'tenant_admin'],
					},
					{
						path: '/calendar/create',
						exact: true,
						component: CalendarCreateComponent,
						isNav: false,
						name: 'calendarCreate',
						label: 'Calendar',
						icon: 'fa fa-users',
						allowedRole: ['ticket_admin', 'tenant_admin'],
					},
					{
						path: '/calendar/update/:itemId',
						exact: true,
						component: CalendarDetailComponent,
						isNav: false,
						name: 'calendarUpdate',
						label: 'Calendar',
						icon: 'fa fa-users',
						allowedRole: ['ticket_admin', 'tenant_admin'],
					},
				],
			},
			{
				path: '/profile',
				exact: true,
				component: ProfileContainer,
				isNav: false,
				allowedRole: [
					'billing_analyst',
					'bot_admin',
					'bot_analyst',
					'bot_manager',
					'bot_user',
					'bpm_admin',
					'bpm_analyst',
					'chat_agent',
					'chat_agent_admin',
					'chat_agent_supervisor',
					'tenant_admin',
					'ticket_admin',
					'ticket_agent',
					'ticket_agent_supervisor',
					'ticket_user',
					'user_admin',
				],
			},

			// { path: '/dashboard', exact: true, component: TestDashboard, isNav: true, name: 'Dashboard', icon: 'fa fa-cogs', allowedRole: ['user_admin'] },
			{
				path: '/Support',
				icon: 'fa fa-tags',
				exact: true,
				component: /* subdomain ? lPage :*/ SupportTicketListing,
				isNav: false,
				allowedRole: ['support_supervisor', 'support_user'],
				name: 'supportTickets',
				label: 'My Support Tickets',
			},
			{
				path: '/Support/createTicket',
				exact: true,
				component: SupportTicket,
				isNav: false,
				name: 'supportTicket',
				label: 'Support Ticket',
				icon: 'fa fa-support',
				allowedRole: ['support_supervisor', 'support_user'],
			},
			{
				path: '/Support/ticket-detail/:caseRefId',
				exact: true,
				component: DetailSupportTicket,
				isNav: false,
				name: 'supportTicketDetail',
				label: 'Support Ticket Detail',
				icon: 'fa fa-support',
				allowedRole: ['support_supervisor', 'support_user'],
			},
			{
				path: '/tenant-management',
				exact: true,
				component: TenantPageList,
				isNav: true,
				name: 'tenant',
				label: 'Tenant Mgmt',
				icon: 'fa fa-users',
				allowedRole: ['asc_admin', 'partner_admin'],
			},
			{
				path: '/tenant-management/create',
				exact: true,
				component: CreateTenantDetail,
				isNav: false,
				name: 'tenant',
				label: 'Tenant Mgmt',
				icon: 'fa fa-users',
				allowedRole: ['asc_admin', 'partner_admin'],
			},
			{
				path: '/tenant-management/update/:itemId',
				exact: true,
				component: TenantPageDetail,
				isNav: false,
				name: 'tenant',
				label: 'Tenant Mgmt',
				icon: 'fa fa-users',
				allowedRole: ['asc_admin', 'partner_admin'],
			},
		],
	},



	{
		path: aibot ? '/virtualagent/aibot' : '/virtualagent',
		exact: true,
		app: { path: aibot ? 'virtualagent/aibot' : 'virtualagent' },
		isNav: true,
		name: 'virtualAgent',
		label: 'Virtual Agent',
		icon: <MdOutlineSupportAgent />,
		allowedRole: ['bot_user'],
	},
	...(!v2Only ? betaRoutes : []),
	{ component: ErrorPage, isNav: false },
]
export const routeListCustom = []

export const routeChildren = routesValues => {
	let filterChildren = routesValues.filter(route => route.allowedRole && route.children)
	let childRoutes = []
	filterChildren &&
		filterChildren.forEach((route, index) => {
			let childs =
				route.children &&
				route.children
					.filter(route => route.allowedRole)
					.map(route => {
						let nextChilds =
							route.children && route.children.filter(route => route.allowedRole).map(route => <PrivateRoute key={route.path || 'error'} {...route} />)
						childRoutes.push(nextChilds)
						return <PrivateRoute key={route.path || 'error'} {...route} />
					})
			childRoutes.push(childs)
		})

	return childRoutes
}

export const RootRouter = () => {
	return (
		<BrowserRouter history={history} basename={appRootPath}>
			<Switch>
				{routeList
					.filter(route => route.allowedRole && !route.app)
					.map(route => (
						<PrivateRoute key={route.path || 'error'} {...route} />
					))}
				{routeChildren(routeList)}
				{routeListCustom
					.filter(route => route.allowedRole && !route.app)
					.map(route => (
						<PrivateRouteCustom key={route.path || 'error'} {...route} />
					))}
				{routeChildren(routeListCustom)}
				{routeList
					.filter(route => !route.allowedRole && !route.app)
					.map(route => (
						<Route key={route.path || 'error'} {...route} />
					))}
				<Route key="Home" Component={Home} isNav={false} />
			</Switch>
		</BrowserRouter>
	)
}
export const localeLoader = (routePath, tenantId) => {
	return false
}
