import { CheckCircleIcon, ChevronDownIcon, SearchIcon, Icon, InfoIcon } from '@chakra-ui/icons'
import {
	Box,
	Button,
	Center,
	Flex,
	FormControl,
	FormLabel,
	Input,
	InputGroup,
	InputRightElement,
	Link,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Progress,
	Radio,
	RadioGroup,
	Select,
	Spinner,
	Text,
	Tooltip,
	useDisclosure,
	useToast,
} from '@chakra-ui/react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { DropdownList, Multiselect, Combobox } from 'react-widgets'
import { identityServices } from '../../..'
import '../../../css/style.css'
import { Creatable } from 'react-select';
import { debounce } from 'lodash'

const AzureContainer = props => {
	const { t } = props
	const defaultRoles = ['Ticket User', 'Bot User']
	const { isOpen, onOpen, onClose } = useDisclosure()
	const toast = useToast()
	const [currentStep, setCurrentStep] = useState(1)
	const [tenants, setTenants] = useState([])
	const [selectedTenant, setSelectedTenant] = useState('')
	const [clientId, setClientId] = useState('')
	const [clientSecret, setClientSecret] = useState('')
	const [roles, setRoles] = useState(defaultRoles)
	const [users, setUsers] = useState([])
	const [options, setOptions] = useState([])
	const roleOptions = ['Ticket User', 'Bot User']
	const [items, setItems] = useState([{ attribute: '', property: '', isMulti: false, isOne: false, isCreate: false, isCreateMulti: false, isText: true }])
	const [report, setReport] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [graphProperty, setGraphProperty] = useState([])
	const [valueLabels, setValueLabels] = useState([])
	const [selectedOptions, setSelectedOptions] = useState([])
	const [inputGraphProperty, setInputGraphProperty] = useState('')
	const [message, setMessage] = useState('')
	const [postBody, setPostBody] = useState([])
	const [rollBack, setRollBack] = useState(false)
	const [reportLoading, setReportLoading] = useState(false)
	const [buffer, setBuffer] = useState(false)
	const [commit, setCommit] = useState(false)
	const [teamsId, setTeamsId] = useState([])
	const [selectedTeamId, setSelectedTeamId] = useState('')
	const [installTeamsPackage, setInstallTeamsPackage] = useState(false)
	const [teamsIdLoading, setTeamsIdLoading] = useState(false)
	const [syncOption, setSyncOption] = useState('entire')
	const [specificUsers, setSpecificUsers] = useState([])
	const [selectedGroupId,setSelectedGroupId] = useState([])
	const [groupFlag,setGroupFlag] = useState(false)
	const dropdownRef = useRef(null)

	const handleContinue = event => {
		let apiUrl = sessionStorage.getItem('apiUrl')
		if (event.target.textContent === 'Continue') {
			if(groupFlag){
				setIsLoading(true)
				const requestOptions = {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
					},
					url: `${apiUrl}/msteams/getAADMembersByMultipleGroupId?ids=${selectedGroupId.join(',')}`,
				}
			identityServices.identityApi
				.request(requestOptions)
				.then(res => {
					setIsLoading(false)
					setUsers(res?.data)
					if (res.data.length === 0) {
						setIsLoading(false)
						return toast({
							title: 'Error',
							description: 'No User data found',
							status: 'error',
							duration: 5000,
							isClosable: true,
							position: 'top-right',
						})
					}
					setCurrentStep(currentStep + 1)
				})
				.catch(err => {
					setIsLoading(false)
					return toast({
						title: 'Error',
						description: err?.response?.data?.message,
						status: 'error',
						duration: 5000,
						isClosable: true,
						position: 'top-right',
					})
				})
			}
			else {
				setIsLoading(true)
				const requestOptions = {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
					},
					url: `${apiUrl}/msteams/syncAADusers/${selectedTenant}`,
				}
				identityServices.identityApi
				.request(requestOptions)
				.then(res => {
					setIsLoading(false)
					const existingUserNames = new Set()
					const uniqueUsers = res.data.reduce((accumulator, item, index) => {
						if (!existingUserNames.has(item.mail?.toLowerCase())) {
							existingUserNames.add(item.mail?.toLowerCase())
							accumulator.push(item)
						}
						return accumulator
					}, [])
					setUsers(uniqueUsers)
					if (res.data.length === 0) {
						setIsLoading(false)
						return toast({
							title: 'Error',
							description: 'No User data found',
							status: 'error',
							duration: 5000,
							isClosable: true,
							position: 'top-right',
						})
					}
					setCurrentStep(currentStep + 1)
				})
				.catch(err => {
					toast({
						title: 'Error',
						description: err?.response?.data?.message,
						status: 'error',
						duration: 5000,
						isClosable: true,
						position: 'top-right',
					})
					// setCurrentStep(currentStep + 1)
				})
			}
			fetchData()
		}
		if (event.target.textContent === 'Save and Continue') {
         let checkUserAttributeField=false
			setBuffer(true)
			fetchTeamsPackageData()
			const values = items?.map(item => `${item.attribute}:${item.property}`)
			const attributesObj = items.reduce((obj, item) => {
              if(item.attribute==""||item.property=="") return checkUserAttributeField=true
				obj[item.attribute] = item.property
				return obj
			}, {})
         if(checkUserAttributeField){
			setCurrentStep(currentStep)
			return toast({
				title: 'Error',
				description: "Please Fill All UserAttribute Field",
				isClosable: true,
				status: 'error',
				position: 'top-right',
				})
		   }
			const freeTextValidation = users[0]
			let postData = users?.map(user => {
				const attributes = {}
				Object.entries(attributesObj).forEach(([key, value]) => {
					if (Array.isArray(value)) {
						attributes[key] = value
					} else if (!freeTextValidation.hasOwnProperty(value)) {
						attributes[key] = value
					} else {
						attributes[key] = [user[value]]
					}
				})

				return {
					username: user.mail || '',
					attributes,
					enabled: true,
					email: user.mail || '',
					firstName: user.givenName || '',
					lastName: user.surname || '',
					roles: defaultRoles,
					teamsId: user.id,
				}
			})
			setPostBody(postData)
			setIsLoading(true)
			postData = postData.filter(item => item.username && item.firstName && item.lastName && item.email)
			const requestOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/json', clientApp: 'botapi', realmName: props.tenant.tenantId },
				url: `${apiUrl}/role-management/bulkUpsertByJob?syncUser=true`,
				data: postData,
			}

			identityServices.identityApi
				.request(requestOptions)
				.then(res => {
					let responseData = res.data
					setIsLoading(false)
					setMessage(responseData.message)
				})
				.catch(err => {
					setIsLoading(false)
					toast({
						title: 'Error',
						description: err.message,
						status: 'error',
						duration: 5000,
						isClosable: true,
						position: 'top-right',
					})
				})
			setCurrentStep(currentStep + 1)
		}

		if (event.target.textContent === 'Import and Deploy Bot') {
			setCurrentStep(currentStep + 1)
			setInstallTeamsPackage(true)
		}
		if (event.target.textContent === 'Import') {
			setCurrentStep(currentStep + 1)
			setInstallTeamsPackage(false)
		}
	}

	const fetchTeamsPackageData = () => {
		setTeamsIdLoading(true)
		let apiUrl = sessionStorage.getItem('apiUrl')
		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json', realmName: props.tenant.tenantId },
			url: `${apiUrl}/msteams/getPackageDetails?aadTenantId=${selectedTenant}`,
		}

		identityServices.identityApi
			.request(requestOptions)
			.then(res => {
				if (res.data && res.data.length === 1) {
					setSelectedTeamId(res.data[0].id)
				}
				setTeamsId(res.data)
				setTeamsIdLoading(false)
			})
			.catch(err => {
				toast({
					title: 'Error',
					description: err?.response?.data?.message,
					status: 'error',
					duration: 5000,
					isClosable: true,
					position: 'top-right',
				})
				setTeamsIdLoading(false)
			})
		setCurrentStep(currentStep + 1)
	}

	const ProgressBar = ({ isLoading }) => {
		const [progress, setProgress] = useState(10)

		useEffect(() => {
			let interval
			if (isLoading) {
				interval = setInterval(() => {
					setProgress(prevProgress => {
						if (prevProgress >= 90) {
							clearInterval(interval)
							return prevProgress
						} else {
							return prevProgress + 1
						}
					})
				}, 1000)
			}

			return () => {
				clearInterval(interval)
			}
		}, [isLoading])

		useEffect(() => {
			document.documentElement.style.setProperty('--progress-width', isLoading ? `${progress}%` : '0')
		}, [isLoading, progress])

		return (
			<Box bg="gray.200" w="50%" mx="auto" my={4}>
				<Progress
					transition="all 0.5s ease"
					position="relative"
					zIndex={1}
					mb={2}
					animation={`${isLoading ? 'progressAnimation 2s linear infinite' : 'none'}`}
					color="blue.500"
				/>
			</Box>
		)
	}

	async function fetchData() {
		let apiUrl = sessionStorage.getItem('apiUrl')
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
			url: `${apiUrl}/UserAttribute/findAndCountAll?$orderby=updatedAt%20desc&$skip=0&$top=100`,
		}
		identityServices.identityApi
			.request(requestOptions)
			.then(res => {
				const data = res.data
				const newOptions = data?.rows?.map(row => ({ label: row.key, value: row.key }))
				setOptions(newOptions)
				setGraphProperty(res.data?.rows)
				// setCurrentStep(currentStep + 1)
			})
			.catch(err => {
				toast({
					title: 'Error',
					description: err.message,
					status: 'error',
					duration: 5000,
					isClosable: true,
					position: 'top-right',
				})
			})
	}

	const handleClose = event => {
		props.showAzurePopup()
		setRollBack(false)
		setBuffer(false)
		setCommit(false)
		onClose()
		if (report && currentStep === 3) {
			window.location.reload()
		}
	}
	const handleOpen = event => {
		onOpen()
	}

	const handleRolesChange = selectedRoles => {
		setRoles(selectedRoles)
	}

	const handleAddItem = () => {
		//   setItems([...items, { attribute: "", property: "" }]);
		const newItems = [...items]
		newItems.push({ attribute: '', property: '', isMulti: false, isOne: false, isCreate: false, isCreateMulti:false, isText: true })
		setItems(newItems)
	}

	const handleAttributeChange = (index, value) => {
		const newItems = [...items]
		newItems[index].attribute = value?.value || value
		//const newOptions = options.filter((option) => option.value !== value)
		let selectedAttribute = graphProperty?.find(ele => ele.key === value.value)
		if (
			(selectedAttribute?.isMultivalue == 'Yes' || selectedAttribute?.isMultivalue == 'No') &&
			(!selectedAttribute?.validationRule || selectedAttribute?.validationRule?.field == 'Predefined List')
		) {
			if (selectedAttribute?.isMultivalue == 'Yes') {
				newItems[index].isOne = false
				newItems[index].isMulti = true
				newItems[index].isText = false
				newItems[index].isCreate = false
				newItems[index].isCreateMulti = false
				setValueLabels(selectedAttribute?.valueLabels)
			} else {
				newItems[index].isOne = true
				newItems[index].isMulti = false
				newItems[index].isText = false
				newItems[index].isCreate = false
				newItems[index].isCreateMulti = false
				const newOptions = selectedAttribute?.valueLabels?.map(i => ({ label: i, value: i }))
				setValueLabels(newOptions)
			}
		}
		if (selectedAttribute?.validationRule && (selectedAttribute?.validationRule?.field == 'Free Text' || selectedAttribute?.validationRule?.field == 'number')) {
			newItems[index].isOne = false
			newItems[index].isMulti = false
			newItems[index].isText = true
			newItems[index].isCreate = false
			newItems[index].isCreateMulti = false
		}
		if(selectedAttribute?.validationRule && (selectedAttribute?.validationRule?.field == 'Unique Values')){
			newItems[index].isOne = false
			newItems[index].isMulti = false
			newItems[index].isText = false
			newItems[index].isCreate = true
			if(selectedAttribute?.isMultivalue == 'Yes') {
				newItems[index].isCreateMulti = true
			} else if (selectedAttribute?.isMultivalue == 'No') {
				newItems[index].isCreateMulti = false
			}
			const newOptions = selectedAttribute?.valueLabels?.map(i => ({ label: i, value: i }))
			setValueLabels(newOptions)
		}
		if(!selectedAttribute) {
			newItems[index].isOne = false
			newItems[index].isMulti = false
			newItems[index].isText = true
			newItems[index].isCreate = false
			newItems[index].isCreateMulti = false
		}
		setItems(newItems)
	}

	const handlePropertyChange = (index, value) => {
		setInputGraphProperty(value)
		const newItems = [...items]
		newItems[index].property = value
		setItems(newItems)
	}

	const handleTenantSelect = event => {
		const selectedTenantId = event.target.value
		if (selectedTenantId === '') {
			// User selected "Choose AAD Tenant", reset the data
			setSelectedTenant('')
			setClientId('')
			setClientSecret('')
			return
		}

		setSelectedTenant(selectedTenantId)

		// Find the corresponding tenant object
		const selectedTenantObject = tenants.find(tenant => tenant.aadTenantId === selectedTenantId)

		// Update the client ID and client secret fields
		setClientId(selectedTenantObject.clientId)
		setClientSecret(selectedTenantObject.clientSecret)
	}

	const handleTeamsSelect = event => {
		const selectedTeamsId = event.target.value
		setSelectedTeamId(selectedTeamsId)
	}

	const handleMultiSelect = (index, value) => {
		setSelectedOptions(value)
		const newItems = [...items]
		newItems[index].property = value
		setItems(newItems)
	}

	const handleCreatable = ( index, value ) => {
		const newItems = [...items];
		newItems[index].property = items[index]?.isCreateMulti ? value.map(item => item.value) : [value.value];
		setItems(newItems);
	}

	const handleRemoveItem = index => {
		let newItems = [...items]
		newItems.splice(index, 1)
		setItems(newItems)
	}

	const handleBack = () => {
		setCurrentStep(currentStep - 1)
		setItems([{ attribute: '', property: '', isMulti: false, isOne: false, isCreate:false, isCreateMulti: false, isText: true }])
	}

	const handleDropdownSelect = (index, value) => {
		setSelectedOptions(value?.value)
		const newItems = [...items]
		newItems[index].property = [value?.value]
		setItems(newItems)
	}

	const handleRollback = () => {
		setBuffer(false)
		setRollBack(true)
		setCommit(false)
	}

	const fetchGroups = useCallback(() => {
		setIsLoading(true)
			let apiUrl = sessionStorage.getItem('apiUrl')
			const requestOptions = {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                },
                url: `${apiUrl}/msteams/getAADTeam/${selectedTenant}`,
              };
              identityServices.identityApi
              .request(requestOptions)
              .then((res) => {
				setIsLoading(false)
				setSpecificUsers(res?.data)
              })
              .catch((err) => {
				toast({
					title: 'Error',
					description: err?.response?.data?.message || 'Error in Fetching Groups',
					status: 'error',
					duration: 5000,
					isClosable: true,
					position: 'top-right',
				})
				setIsLoading(false)
			  });
	},[selectedTenant])

	useEffect(() => {
		// Function to handle clicks outside the dropdown
		const handleClickOutside = (event) => {
		  if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			// setShowDropdown(false)
		  }
		};
	
		// Adding event listener to detect clicks outside the dropdown
		document.addEventListener('mousedown', handleClickOutside);
	
		// Cleanup function to remove event listener when component unmounts
		return () => {
		  document.removeEventListener('mousedown', handleClickOutside);
		};
	  }, [])

	const handleRadioChange = useCallback((value) => {
		setSyncOption(value)
		if (value === 'specific') {
			setGroupFlag(true)
			fetchGroups()
		} else {
			setGroupFlag(false)
		}
	  },[selectedTenant])

	useEffect(()=>{
		if(groupFlag){
			fetchGroups()
		}
	},[selectedTenant])

	const handleMultipleGroups = (value) => {
		let selectedIds = value.map(user => user.id)
		setSelectedGroupId(selectedIds)
	}

	const handleCommit = () => {
		setCommit(true)
		setBuffer(false)
		let apiUrl = sessionStorage.getItem('apiUrl')
		setReportLoading(true)
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json', clientApp: 'botapi', realmName: props.tenant.tenantId },
			url: `${apiUrl}/role-management/bulkUpsertByJob?installTeamsPackage=${installTeamsPackage}&aadTenantId=${selectedTenant}&botId=${selectedTeamId}`,
			data: postBody,
		}
		identityServices.identityApi
			.request(requestOptions)
			.then(res => {
				let responseData = res.data
				setReportLoading(false)
				setReport(responseData.requestId)
			})
			.catch(err => {
				setIsLoading(false)
				toast({
					title: 'Error',
					description: err.message,
					status: 'error',
					duration: 5000,
					isClosable: true,
					position: 'top-right',
				})
			})
	}
	const step1 = () => {
		return (
			<>
				<FormControl>
					<Flex justifyContent={'space-between'} mb={7} alignItems={'center'}>
						<FormLabel>Tenant ID:</FormLabel>
						<Select onChange={handleTenantSelect} value={selectedTenant} w="75%">
							<option value="">Choose AAD Tenant ID</option>
							{tenants &&
								tenants.length &&
								tenants?.map(tenant => (
									<option key={tenant.aadTenantId} value={tenant.aadTenantId}>
										{tenant.aadTenantId}
									</option>
								))}
						</Select>
					</Flex>
				</FormControl>
				<FormControl>
					<Flex justifyContent={'space-between'} mb={7} alignItems={'center'}>
						<FormLabel>Client ID:</FormLabel>
						<Input isReadOnly defaultValue={clientId} style={{ width: '75%' }} />
					</Flex>
				</FormControl>
				<FormControl>
					<Flex justifyContent={'space-between'} mb={7} alignItems={'center'}>
						<FormLabel>Client Secret:</FormLabel>
						<Input isReadOnly defaultValue={clientSecret} style={{ width: '75%' }} />
					</Flex>
				</FormControl>
				<FormControl>
					<Flex justifyContent={'space-between'} mb={7} alignItems={'center'} >
						<FormLabel>Sync AD users:</FormLabel>
						<RadioGroup onChange={handleRadioChange} value={syncOption} style={{ width: '75%' }}>
								<Flex alignItems={'center'} spacing={5} justifyContent={'space-around'}>
									<Radio value="entire">Sync Entire AD Users</Radio>
									<Radio value="specific" isDisabled={!selectedTenant || !clientId || !clientSecret}>Sync Specific Groups</Radio>
								</Flex>
          				</RadioGroup>
					</Flex>
					{syncOption === 'specific' && (
          				<Flex justifyContent={'space-between'} mb={10} alignItems={'center'}>
						  <FormLabel>AAD Group(s):</FormLabel>
						    {isLoading ? 
								<Center w="75%">
									<Spinner size="lg" thickness="1px" speed="0.65s" emptyColor="gray.200" color="green.500" />
								</Center> :
								<>
									<Multiselect
										data={specificUsers}
										textField="displayName"
										valueField="id"
										value={selectedGroupId}
										onChange={handleMultipleGroups}
										style={{ width: '75%' }}
										placeholder="AAD Group(s)"
									/>
								</>
    						}
					    </Flex>
        			)}
				</FormControl>
			</>
		)
	}

	const step2 = () => {
		return (
			<>
				<FormControl>
					<Flex justifyContent={'space-between'} mb={7} alignItems={'center'}>
						<FormLabel htmlFor="roles">Roles</FormLabel>
						<Multiselect id="roles" data={roleOptions} value={roles} onChange={handleRolesChange} style={{ width: '75%' }} readOnly={true} />
					</Flex>
				</FormControl>
				<Text>Attributes :</Text>
				<FormControl justifyContent={'center'}>
					{items?.map((item, index) => (
						<Flex key={index} justifyContent="space-between" mb={7} alignItems="center" width="100%">
							<Combobox
								onChange={value => handleAttributeChange(index, value)}
								value={options?.value}
								placeholder={t('User Attributes')}
								style={{ width: '40%' }}
								data={options}
								textField="label"
								valueField="value"
								className='combo-input'
							/>
							{item?.isMulti && (
								<Multiselect
									data={valueLabels}
									value={item?.property || []}
									maxLength={1}
									onChange={value => handleMultiSelect(index, value)}
									style={{ width: '40%' }}
									placeholder={t('Attribute Values')}
								/>
							)}
							{item?.isText && (
								<InputGroup style={{ width: '40%' }}>
									<Input value={item.property} onChange={e => handlePropertyChange(index, e.target.value)} placeholder={t('Graph Property')} />
									{item.property && (
										<InputRightElement>
											{users.some(ele => ele.hasOwnProperty(item.property)) ? (
												<Tooltip label="Graph Property Matched With the AAD Attribute">
													<Icon as={CheckCircleIcon} color="green.500" boxSize={4} />
												</Tooltip>
											) : (
												<Tooltip label="Graph Property is not matching with AAD Attribute,It will take as attribute">
													<Icon as={InfoIcon} color="blue.500" boxSize={4} />
												</Tooltip>
											)}
										</InputRightElement>
									)}
								</InputGroup>
							)}
							{item?.isOne && (
								<DropdownList
									onChange={value => handleDropdownSelect(index, value)}
									value={item?.property}
									placeholder={t('Attribute Values')}
									style={{ width: '40%' }}
									data={valueLabels}
									textField="label"
									valueField="value"
								/>
							)}
							{item?.isCreate && (
								<Box style= {{width:'40%'}}>
									<Creatable
										options={valueLabels}
										onChange={value => handleCreatable(index, value)}
										placeholder={t('Attribute Values')}
										isMulti={item?.isCreateMulti}
										closeMenuOnSelect={!item?.isCreateMulti}
										style={{ width: '40%' }}
									/>
								</Box>
            				)}
							<Button
								onClick={() => handleRemoveItem(index)}
								disabled={items.length === 1}
								style={{
									color: 'black',
									backgroundColor: 'white',
									border: 'none',
									fontSize: '16px',
									width: '20px',
									height: '20px',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									borderRadius: '50%',
								}}>
								X
							</Button>
						</Flex>
					))}
				</FormControl>
				<FormControl>
					<Button onClick={handleAddItem} style={{ color: '#007bff', fontWeight: 'bold' }} bg="white" >
						+ Add Attributes
					</Button>
				</FormControl>
			</>
		)
	}

	const step4 = () => {
		return (
			<>
				<Box>
					{buffer &&
						(isLoading ? (
							<Box textAlign="center" fontSize="16px">
								Please wait while we are validating the users.
								<ProgressBar isLoading={isLoading} />
							</Box>
						) : (
							<Box fontWeight="bold" textAlign="center" fontSize="16px">
								Please commit to update the users.
								<Text color="blue.500">{message}</Text>
								<Box>
									<Flex p={4} justifyContent="flex-end">
										<Button mr={4} onClick={handleRollback}>
											{' '}
											Roll Back{' '}
										</Button>
										<Button mr={4} onClick={handleCommit} colorScheme="blue">
											{' '}
											Commit{' '}
										</Button>
									</Flex>
								</Box>
							</Box>
						))}

					{rollBack && (
						<>
							<Box display="flex" justifyContent="center" alignItems="center" height={100}>
								<Box textAlign="center">
									<Text fontWeight="bold" fontSize="16px">
										Request Roll Back
									</Text>
								</Box>
							</Box>{' '}
						</>
					)}

					{commit &&
						(reportLoading ? (
							<Flex justifyContent="center" alignItems="center">
								<Spinner size="xl" thickness="2px" speed="0.65s" emptyColor="gray.200" color="green.500" />
							</Flex>
						) : (
							<Text fontWeight="bold" textAlign="center" fontSize="16px">
								Your Request is in Progress,Click Here:
								<Link href="/botmanagement/requests" display="inline-block" textAlign="center" color="blue.500" target="_blank">
									{report}
								</Link>{' '}
								to check the status.
							</Text>
						))}
				</Box>
			</>
		)
	}

	const step3 = () => {
		return (
			<>
				<FormControl>
					<Flex justifyContent={'space-between'} mb={7} alignItems={'center'}>
						{teamsIdLoading ? (
							<Center w="100%" h="100%">
								<Spinner />
							</Center>
						) : (
							<>
								<FormLabel>Teams ID:</FormLabel>
								<Select onChange={handleTeamsSelect} value={selectedTeamId} w="75%">
									<option value="">Choose Bot ID</option>
									{teamsId &&
										teamsId.length &&
										teamsId?.map(tenant => (
											<option key={tenant.id} value={tenant.id}>
												{tenant.id}
											</option>
										))}
								</Select>
							</>
						)}
					</Flex>
				</FormControl>
			</>
		)
	}

	useEffect(() => {
		let apiUrl = sessionStorage.getItem('apiUrl')
		const fetchData = async () => {
			const requestOptions = {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
				url: `${apiUrl}/msteams?$select=aadTenantId,clientId,clientSecret,botAppId,botAppSecret,botDirectlineSecret,botMSId,tenantName,attributeMap,status,tenantId,createdBy&$skip=0&$top=50`,
			}
			identityServices.identityApi
				.request(requestOptions)
				.then(res => {
					const tenants = res.data && res.data?.filter(tenant => tenant.status === 'Published')
					if (tenants?.length === 1) {
						setSelectedTenant(tenants[0].aadTenantId)
						setClientId(tenants[0].clientId)
						setClientSecret(tenants[0].clientSecret)
					}
					setTenants(tenants)
				})
				.catch(err =>
					toast({
						title: 'Error',
						description: err.message,
						status: 'error',
						duration: 5000,
						isClosable: true,
						position: 'top-right',
					})
				)
		}
		fetchData()
	}, [])

	useEffect(() => {
		onOpen()
	})

	return (
		<div>
			<Modal isOpen={isOpen} onClose={onClose} size="4xl">
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Import from Azure AD</ModalHeader>
					<ModalCloseButton onClick={handleClose} />
					<hr />
					<ModalBody m={3} height="600px">
						<Flex>
							<Box w="25%">
								<Flex direction={'column'}>
									<Flex alignItems={'center'}>
										<CheckCircleIcon color={currentStep >= 1 ? 'blue.500' : 'gray.300'} marginRight={2} />
										<Text fontWeight="500">Choose Domain</Text>
									</Flex>
									<Flex alignItems="left">
										<Box h={10} bg="black" my={2} borderLeft="2px solid #718096" marginLeft={2} />
									</Flex>
									<Flex alignItems={'center'}>
										<CheckCircleIcon color={currentStep >= 2 ? 'blue.500' : 'gray.300'} marginRight={2} />
										<Text fontWeight="500">Map Attribute</Text>
									</Flex>
									<Flex alignItems="left">
										<Box h={10} bg="black" my={2} borderLeft="2px solid #718096" marginLeft={2} />
									</Flex>
									<Flex alignItems={'center'}>
										<CheckCircleIcon color={currentStep >= 3 ? 'blue.500' : 'gray.300'} marginRight={2} />
										<Text fontWeight="500">Deploy on Teams</Text>
									</Flex>
									<Flex alignItems="left">
										<Box h={10} bg="black" my={2} borderLeft="2px solid #718096" marginLeft={2} />
									</Flex>
									<Flex alignItems={'center'}>
										<CheckCircleIcon color={currentStep >= 4 ? 'blue.500' : 'gray.300'} marginRight={2} />
										<Text fontWeight="500">Operation Details</Text>
									</Flex>
								</Flex>
							</Box>
							<Box h="auto" borderLeft="2px solid #718096" mx={2}></Box>
							<Box w="75%">
								{currentStep === 1 && step1()}
								{currentStep === 2 &&
									(isLoading ? (
										<Flex justifyContent="center" alignItems="center" direction={'column'}>
											<Text mt={4}>Please wait while we are fetching details.</Text>
											<Spinner size="xl" thickness="2px" speed="0.65s" emptyColor="gray.200" color="green.500" />
										</Flex>
									) : (
										<>{step2()}</>
									))}
								{currentStep === 3 && step3()}
								{currentStep === 4 && step4()}
							</Box>
						</Flex>
					</ModalBody>
					<Box>
						<Flex p={4} justifyContent="flex-end">
							{(currentStep === 1 || currentStep === 3) && (
								<Button mr={4} onClick={handleClose}>
									{currentStep === 3 ? 'Close' : 'Cancel'}
								</Button>
							)}
							{currentStep === 2 && (
								<Button mr={4} onClick={handleBack} disabled={isLoading}>
									{' '}
									Back{' '}
								</Button>
							)}
							{currentStep === 3 && (
								<>
									<Button mr={4} onClick={handleContinue}>
										Import
									</Button>
									<Button mr={4} colorScheme="blue" disabled={!selectedTeamId} onClick={handleContinue}>
										Import and Deploy Bot
									</Button>
								</>
							)}
							{currentStep < 3 && (
								<Button colorScheme="blue" disabled={!selectedTenant || isLoading} onClick={handleContinue}>
									{currentStep === 1 ? 'Continue' : currentStep === 2 ? 'Save and Continue' : ''}
								</Button>
							)}
						</Flex>
					</Box>
				</ModalContent>
			</Modal>
		</div>
	)
}

export default AzureContainer
